<template>
  <div class="book-class-container">
    <PageLayout>
      <template #page-name>
        <div class="nav-title font-menu-large">
          <div
            class="nav-title-item"
            v-for="item in navList"
            :key="item.id"
            :class="{ active: item.id === currentPage }"
            @click="changePage(item.id)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="your-course-detail">
          <div class="total-calsses">
            <div class="class-number font-menu-xlarge">
              {{ contractCourseInfo.totalClasses }}
            </div>
            <div class="title font-menu-small">TOTAL CLASSES</div>
          </div>
          <div class="completed-classes">
            <div class="class-number font-menu-xlarge">
              {{ contractCourseInfo.completed }}
            </div>
            <div class="title font-menu-small">COMPLETED</div>
          </div>
          <div class="not-completed-classes">
            <div class="remaning-classes">
              <div class="class-number font-menu-xlarge">
                {{ contractCourseInfo.remaining }}
              </div>
              <div class="title font-menu-small">REMANING</div>
            </div>
            <div class="schedule-classes">
              <div class="not-scheduled">
                <div class="class-number font-menu-xlarge">
                  {{ contractCourseInfo.notScheduled }}
                </div>
                <div class="title font-menu-small">NOT SCHEDULED</div>
              </div>
              <div class="scheduled">
                <div class="class-number font-menu-xlarge">
                  {{ contractCourseInfo.scheduled }}
                </div>
                <div class="title font-menu-small">SCHEDULED</div>
              </div>
            </div>
          </div>
        </div>
        <div class="book-class font-menu-medium">
          <span>Book a class</span>
        </div>
      </template>

      <template #page-content>
        <div class="content">
          <div class="book-class-content-nav class-classify font-menu-medium">
            <div
              class="classify-item"
              v-for="(item, index) in bookClassWays"
              :key="index"
              :class="{ active: bookClassWaysIndex === index }"
              @click="handleChangeBookClassWay(index)"
            >
              {{ item.value }}
            </div>
          </div>
          <div class="book-class-by-date" v-if="bookClassWaysIndex === 1">
            <!-- choose time -->
            <div class="choose-class-time-title">
              <div class="circle"></div>
              <div class="title font-menu-medium">Choose Class Time</div>
              <div
                class="show-class-time-area"
                v-if="isFinishChooseTime"
                @click="isFinishChooseTime = false"
              >
                {{ bookSingleClassInfo.date + " | " + pieceTime }}
                <div class="change-date-btn">CHANGE</div>
              </div>
            </div>
            <div class="choose-class-time" v-if="!isFinishChooseTime">
              <div class="duration-area font-menu-medium">
                <p>Duration</p>
                <div
                  class="duration-item"
                  v-for="(item, index) in 2"
                  :key="index + 'duration'"
                  :class="{ active: durationIndex === index }"
                  @click="handleChangeDuration(item, index)"
                >
                  {{ item }}
                </div>
              </div>

              <div class="choose-class-date">
                <Calendar
                  @getFormDate="getRightFormDate"
                  @getRangeDate="getRangeDate"
                  :isPointStyle="true"
                />
              </div>
              <div
                class="class-time"
                :class="{ isDisabled: !bookSingleClassInfo.date }"
              >
                <div
                  class="time-item font-menu-medium"
                  v-for="(item, index) in timeList"
                  :key="index"
                  :class="{
                    active: choosedIndex === index,
                  }"
                  @click="openBook(item, index)"
                >
                  {{ item }}
                </div>
              </div>
            </div>

            <!-- choose teacher -->
            <div class="choose-teacher font-menu-medium" v-else>
              <div class="choose-teacher-title">
                <div class="circle"></div>
                <div class="title">Choose Teacher</div>
                <!-- <div class="teacher-select">
                  <el-select v-model="chooseTeacherType" placeholder="请选择">
                    <el-option
                      v-for="item in teacherOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      popper-class="select-box"
                      @change="changeTeacherOption"
                    >
                    </el-option>
                  </el-select>
                </div> -->
              </div>

              <div class="teacher-list-area">
                <div
                  class="teacher-info-item"
                  v-for="(item, index) in showTeacherList"
                  :key="index + 'teacher'"
                >
                  <div class="single-teacher">
                    <div class="teacher-avatar">
                      <img :src="item.photo" alt="" />
                    </div>
                    <div class="teacher-info">
                      <div class="teacher-info-top">
                        <div class="teacher-info-top-left font-menu-medium">
                          <div class="name">{{ item.teacherName }}</div>
                          <div class="teach-time">
                            {{ item.teachTime }} years teaching experience
                          </div>
                          <div class="score">
                            <StarScore :score="item.score" />
                          </div>
                        </div>
                        <div
                          class="book-btn font-menu-medium"
                          @click.stop="chooseTeacher(item)"
                        >
                          Select
                        </div>
                      </div>

                      <div
                        class="
                          introcution
                          line-word-overflow-wrap
                          font-menu-small
                        "
                      >
                        {{ item.introcution }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="book-class-by-teacher" v-else>
            <!-- choose teacher -->
            <div class="choose-teacher font-menu-medium">
              <div class="choose-teacher-title">
                <div class="circle"></div>
                <div class="title">Choose Teacher</div>

                <!-- 选择老师类型 -->
                <!-- <div class="teacher-select" v-if="!isFinishChooseTeacher">
                  <el-select v-model="chooseTeacherType" placeholder="请选择">
                    <el-option
                      v-for="item in teacherOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      popper-class="select-box"
                      @change="changeTeacherOption"
                    >
                    </el-option>
                  </el-select>
                </div> -->
                <!-- 被选择老师的信息 -->
                <div
                  class="seleted-teacher-info"
                  v-if="isFinishChooseTeacher"
                  @click="isFinishChooseTeacher = false"
                >
                  <div class="teacher-avatar">
                    <img :src="choosedTeacherInfo.photo" alt="" />
                  </div>
                  <div class="teacher-name-area">
                    {{ choosedTeacherInfo.teacherName }}
                    {{ choosedTeacherInfo.teacherNamePy }}
                    <div class="change-date-btn">CHANGE</div>
                  </div>
                </div>
              </div>
              <div
                class="duration-area font-menu-medium"
                v-if="isFinishChooseTeacher"
              >
                <p>Duration</p>
                <div
                  class="duration-item"
                  v-for="(item, index) in 2"
                  :key="index + 'duration'"
                  :class="{ active: durationIndex === index }"
                  @click="handleChangeDuration(item, index)"
                >
                  {{ item }}
                </div>
              </div>
              <!-- 老师列表 -->
              <div class="teacher-list-area" v-if="!isFinishChooseTeacher">
                <div
                  class="teacher-info-item"
                  v-for="(item, index) in showTeacherList"
                  :key="index + 'teacher'"
                >
                  <div class="single-teacher">
                    <div class="teacher-avatar">
                      <img :src="item.photo" alt="" />
                    </div>
                    <div class="teacher-info">
                      <div class="teacher-info-top">
                        <div class="teacher-info-top-left font-menu-medium">
                          <div class="name">{{ item.teacherName }}</div>
                          <div class="teach-time">
                            {{ item.teachTime }} years teaching experience
                          </div>
                          <div class="score">
                            <StarScore :score="item.score" />
                          </div>
                        </div>
                        <div
                          class="book-btn font-menu-medium"
                          @click.stop="chooseTeacher(item)"
                        >
                          Select
                        </div>
                      </div>

                      <div
                        class="
                          introcution
                          line-word-overflow-wrap
                          font-menu-small
                        "
                      >
                        {{ item.introcution }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- choose class time -->
            <div class="choose-class-time-title" v-if="isFinishChooseTeacher">
              <div class="circle"></div>
              <div class="title font-menu-medium">Choose Class Time</div>
            </div>
            <div
              class="teacher-class-time-chooose-area"
              v-if="isFinishChooseTeacher"
            >
              <Calendar
                @getRangeDate="getRangeDate"
                :isBookClassPage="true"
                class="calendar-teacher"
              />
              <div class="time-box font-menu-medium">
                <div class="choose-block">
                  <div class="time-area">
                    <div
                      class="time-item"
                      v-for="(item, index) in teacerTimeList"
                      :key="index + 'teachTime'"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <div
                    class="block-col"
                    v-for="(item, indexCol) in finalArr"
                    :key="indexCol + 'col'"
                  >
                    <div
                      class="block-row"
                      v-for="(ele, indexRow) in item"
                      :key="indexRow + 'row'"
                      :class="{
                        canBeChoose: ele.isCanUsed,
                        choosedTime:
                          choosedTimeIndexInfo.col === indexCol &&
                          choosedTimeIndexInfo.row === indexRow &&
                          ele.isCanUsed,
                      }"
                      @click="handleChooseTeacherTime(indexCol, indexRow, ele)"
                    >
                      1
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- book button -->
          <div
            class="book-button"
            v-if="bookOpen && (!isFinishChooseTime || !isFinishChooseTeacher)"
            @click="clickRedBookBtn"
          >
            <div class="book">Book</div>
          </div>
        </div>
      </template>
    </PageLayout>
    <div class="book-class-dialog">
      <el-dialog title="Booking Confirmation" :visible.sync="bookClassVisible" center>
        <div class="booking-comfirm-content">
          <div class="class-classify">
            <div class="classify-item">Book this Class</div>
            <!-- <div
              class="classify-item"
              v-for="(item, index) in bookClassClassify"
              :key="index"
              :class="{ active: classClassify === index }"
              @click="classClassify = index"
            >
              {{ item.value }}
            </div> -->
          </div>
          <div class="one-time-class" v-if="classClassify === 0">
            <div class="date-and-time">
              <div class="class-date">
                <div class="key">DATE</div>
                <div class="value">{{ bookSingleClassInfo.date }}</div>
              </div>
              <div class="class-time">
                <div class="key">TIME</div>
                <div class="value">{{ pieceTime }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="recurring-class" v-else>
            <div class="date-and-time">
              <div class="class-date-title">
                <div class="key">FROM</div>
                <div class="key">TO</div>
              </div>
              <div class="date-picker-area">
                <el-date-picker
                  v-model="recurringClassDate"
                  type="daterange"
                  range-separator=""
                  start-placeholder="from"
                  end-placeholder="to"
                  default-value
                  popper-class="pick-date-range"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="class-time">
              <div class="key">CLASS TIME</div>
              <div class="value">{{ pieceTime }} every Tuesday</div>
            </div>
          </div> -->
          <div class="control-button">
            <div class="cancle" @click="cancleBookClass">Cancel</div>
            <div class="book" @click="bookaClass">Book</div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import PageLayout from "@/components/Page/Layout/PageLayout.vue";
import { getPeriodsTimeByDuration, getFormatDate } from "@/utils/tools.js";
import {
  getTeacherListByDate,
  bookClassByDate,
  getTeacherListByStudent,
  getTeacherScheduling,
  getUserContractInfo,
  changeCurrentKid,
} from "@/assets/js/axios/kidsApi.js";
import Calendar from "@/components/Page/Calendar";
import StarScore from "@/components/Page/StarScore";

export default {
  name: "BookClass",
  components: {
    PageLayout,
    Calendar,
    StarScore,
    // UpcomingClasses,
  },
  data() {
    return {
      bookSingleClassInfo: {
        date: "",
        time: "",
      },
      recurringClassDate: "",
      currentPage: 1,
      navList: [
        {
          title: "Your Course",
          id: 1,
        },
      ],
      teacherOptions: [
        { value: "0", label: "All Teacher" },
        // { value: "1", label: "Frequently Used Teacher" },
        { value: "1", label: "Recently Used Teacher" },
      ],
      // chooseTeacherType: "All Teacher",
      chooseTeacherType: "0",
      timeList: [],
      bookOpen: false,
      classTime: "",
      classDate: "",
      bookClassVisible: false,
      bookClassWays: [
        {
          id: 0,
          value: "By teacher",
        },
        {
          id: 1,
          value: "By date",
        },
      ],
      bookClassClassify: [
        {
          id: 0,
          value: "One-time class",
        },
        // {
        //   id: 1,
        //   value: "Recurring classes",
        // },
      ],
      bookClassWaysIndex: 0, // 默认通过老师选课
      classClassify: 0,
      isFinishChooseTime: false,
      isFinishChooseTeacher: false,

      durationIndex: 0,
      duration: 1,
      showTeacherList: [],

      choosedTeacherInfo: {},
      teacerTimeList: [],
      choosedTimeIndexInfo: {},
      finalArr: [],

      choosedIndex: null,
      canUseTime: [], // 不可以排课的时间的时间

      contractCourseInfo: {}, // 合同课程详情
    };
  },
  created() {
    this.initBookPage();
  },
  watch: {
    chooseTeacherType(value) {
      // console.log(Number(value));
      if (Number(value)) {
        // this.showTeacherList.forEach((item) => {
        //   item.classNum = parseInt(Math.random() * 10); // 模拟上课次数
        // });
        this.showTeacherList.sort((a, b) => {
          return b.classNum - a.classNum;
        });
        // console.log(this.showTeacherList);
      } else {
        this.getTeacherListByDate();
      }
    },
    async kidUserId(value) {
      console.log(value);
      this.userId = value;
      if (!this.$store.state.dashboard.isKid) {
        const sendData = {
          id: this.kidUserId,
        };
        const result = await changeCurrentKid(sendData);
        // console.log(result);
        if (result && result.code === 200) {
          localStorage.userData = JSON.stringify(result.data);
          this.$store.commit("setUserInfo", result.data);
          this.initBookPage();
        }
      }
    },
  },
  computed: {
    pieceTime() {
      const { time } = this.bookSingleClassInfo;
      const stopTime = parseInt(time.split(":")[1]) + 25 * this.duration;
      let timeArea = time.split(":")[0] > 12 ? " pm" : " am";
      return `${time}-${time.split(":")[0]}:${stopTime}${timeArea}`;
    },
    kidUserId() {
      return this.$store.state.dashboard.kidUserId;
    },
  },
  // updated() {
  //   this.timeList = [];
  //   this.teacerTimeList = [];
  // },
  methods: {
    initBookPage() {
      this.timeList = [];
      this.teacerTimeList = [];
      const { userId, nickname: userName } =
        this.$store.state.dashboard.userInfo.userSetting;
      const { schoolId } = this.$store.state.dashboard.userInfo.studentSchool;
      this.userId = userId;
      this.userName = userName;
      this.schoolId = schoolId;
      this.timeList.push(...getPeriodsTimeByDuration(this.duration));
      this.teacerTimeList.push(...getPeriodsTimeByDuration(this.duration));
      // console.log(this.teacerTimeList);
      this.getTeacherListByStudent();

      // 获取学生合同课程详情
      this.getStudentCourseDetailInfo();
    },
    async getStudentCourseDetailInfo() {
      const result = await getUserContractInfo();
      if (result && result.data) {
        this.contractCourseInfo = result.data;
      }
    },
    // 获取点击时间的正确时间格式
    getRightFormDate(date, formDate) {
      this.bookSingleClassInfo.date = date;
      this.formDate = formDate;
      // console.log(formDate);
    },
    // 获取周第一天日期
    getRangeDate(startDate, endDate, dateArr, formDateArr, UTCDateList) {
      // console.log(startDate, endDate, dateArr);
      this.startDate = startDate;
      this.endDate = endDate;
      this.dateArr = dateArr;
      this.formDateArr = formDateArr;
      this.UTCDateList = UTCDateList;
      if (this.bookClassWaysIndex === 0) {
        this.getTeacherFreeTime();
      }
    },
    changePage(index) {
      this.currentPage = index;
    },
    //选择订课方式
    handleChangeBookClassWay(index) {
      this.initBookClass();
      this.bookClassWaysIndex = index;
      if (index === 0) {
        // 获取当前学生最近上课老师列表
        this.getTeacherListByStudent();
      }
    },
    handleChangeDuration(item, index) {
      this.durationIndex = index;
      this.duration = item;
      if (this.bookClassWaysIndex === 0) {
        this.teacerTimeList = getPeriodsTimeByDuration(item);
        setTimeout(() => {
          this.getTeacherFreeTime();
        }, 100);
      } else {
        this.timeList = getPeriodsTimeByDuration(item);
        // console.log(this.timeList);
      }
    },
    // 选择老师类型
    changeTeacherOption(value) {},
    //选择老师
    chooseTeacher(item) {
      // console.log(item);
      // to do list :选择老师的信息
      this.choosedTeacherInfo = { ...item };
      if (this.bookClassWaysIndex === 1) {
        this.bookClassVisible = true;
      } else {
        this.isFinishChooseTeacher = true;
        setTimeout(() => {
          this.getTeacherFreeTime();
        }, 100);
      }
    },
    //
    async getTeacherFreeTime() {
      // console.log(this.startDate, this.endDate, this.dateArr);
      const sendData = {
        // teacherId: this.choosedTeacherInfo.teacherId, // 测试用
        teacherId: 24,
        startDate: this.startDate,
        endDate: this.endDate,
        usedToken: this.duration,
      };
      const data = await getTeacherScheduling(sendData);
      // console.log(data);

      // console.log(this.teacerTimeList);

      let teacherList = data.data.map((item) => {
        // console.log(parseInt(item.start.split("T")[1].split(":")[1]));
        return {
          ...item,
          start: parseInt(item.start.split("T")[1].split(":")[0]),
          end: parseInt(item.end.split("T")[1].split(":")[0]),
          date: parseInt(item.start.split("T")[0].split("-")[2]),

          isHalfStart:
            parseInt(item.start.split("T")[1].split(":")[1]) === 30
              ? true
              : false,
          isHalfEnd:
            parseInt(item.end.split("T")[1].split(":")[1]) === 30
              ? true
              : false,
        };
      });

      let testArr = [[], [], [], [], [], [], []];
      teacherList.forEach((item) => {
        for (let i = 0; i <= 6; i++) {
          const element = this.dateArr[i];
          if (item.date === element) {
            testArr[i].push(item);
          }
        }
      });
      // console.log(testArr);
      let finalArr = [[], [], [], [], [], [], []];
      finalArr.forEach((item, index) => {
        // console.log();
        for (let i = 0; i < this.teacerTimeList.length; i++) {
          const obj = {
            isCanUsed: false,
          };
          finalArr[index].push(obj);
        }
      });
      let distance = this.duration === 1 ? 14 : 7;
      let classTimes = this.duration === 1 ? 2 : 1;
      // console.log(finalArr);
      finalArr.forEach((item, index) => {
        // console.log(item);
        item.forEach((ele, ind) => {
          testArr[index].forEach((e) => {
            //  半点处理
            // console.log(e);
            let finalStart, finalEnd;

            if (e.isHalfStart) {
              finalStart = e.start * classTimes + 1;
            } else {
              finalStart = e.start * classTimes;
            }

            if (e.isHalfEnd) {
              finalEnd = e.end * classTimes - 1;
            } else {
              finalEnd = e.end * classTimes;
            }

            if (finalStart <= ind + distance && finalEnd > ind + distance) {
              ele.isCanUsed = true;
            }
          });
        });
      });
      this.finalArr = finalArr;
    },
    // 选择老师时间
    handleChooseTeacherTime(col, row, item) {
      if (!item.isCanUsed) {
        return;
      }
      console.log(col, row);
      const teacherDate = this.formDateArr[col];
      const teacherTime = this.teacerTimeList[row];
      this.choosedTimeIndexInfo = { col, row };
      //打开book按钮
      this.bookOpen = true;

      setTimeout(() => {
        this.$bus.$emit("scrollToBottom");
      }, 100);
      this.bookSingleClassInfo.date = teacherDate;
      this.bookSingleClassInfo.time = teacherTime;
      this.teacherChoosedDateIndex = col;
    },
    // 打开book按钮
    openBook(value, index) {
      if (this.bookSingleClassInfo.date) {
        this.bookOpen = true;
        this.choosedIndex = index;
        this.bookSingleClassInfo.time = value;
        // 滚动条滚到最下面
        setTimeout(() => {
          this.$bus.$emit("scrollToBottom");
        }, 100);
      } else {
        this.$message({
          type: "error",
          message: "Please choose the date first.",
        });
      }
    },

    clickRedBookBtn() {
      // todolist: check all options choosed or not
      if (this.bookClassWaysIndex === 1) {
        this.getTeacherListByDate();
        this.isFinishChooseTime = true;
        this.bookOpen = false;
      } else {
        this.bookClassVisible = true;
      }
    },
    //
    async getTeacherListByDate() {
      const sendData = {
        usedToken: this.duration,
        startDate: this.formDate + " " + this.bookSingleClassInfo.time + ":00",
      };
      //调接口获取老师
      const data = await getTeacherListByDate(sendData);
      // console.log(data);
      if (data.data.length) {
        this.showTeacherList = data.data.splice(0, 10);
        this.showTeacherList.forEach((item) => {
          item.score = "4";
          item.teachTime = "8-10";
          item.introcution =
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam";
        });
      } else {
        this.$message({
          type: "error",
          message: "sorry! no teachers have free time",
        });
      }
    },
    async getTeacherListByStudent() {
      const sendData = {
        usedToken: this.duration,
        // userName:this.userName // 实际情况
        userName: "0", // 模拟name
      };
      //调接口获取老师
      const data = await getTeacherListByStudent(sendData);
      // console.log(data);
      this.showTeacherList = data.data;
      this.showTeacherList.forEach((item) => {
        item.score = "4";
        item.teachTime = "8-10";
        item.introcution =
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam";
      });
    },
    // book class
    async bookaClass() {
      let startDate;
      if (this.bookClassWaysIndex === 1) {
        startDate = this.formDate;
      } else {
        startDate = getFormatDate(
          this.UTCDateList[this.teacherChoosedDateIndex]
        ).split(" ")[0];
      }
      const sendData = {
        usedToken: this.duration,
        startDate: startDate + " " + this.bookSingleClassInfo.time + ":00",
        teacherId: this.choosedTeacherInfo.teacherId,
        teacherName: this.choosedTeacherInfo.teacherName,
        // schoolId: 3,//模拟
        schoolId: this.schoolId,
        // userName: "chad_student  chad_student",//模拟
        userName: this.userName,
        userId: this.userId,
        isKidsLesson: true,
      };
      const data = await bookClassByDate(sendData);
      // console.log(data);
      if (data.code === 200) {
        // 成功跳到home页面
        this.$message({
          message: "Great! Your class has been successfully booked.",
          type: "success",
        });
        this.initBookClass();
        // 成功跳到home页面
        this.$router.push({ name: "Home" });
      }
    },
    initBookClass() {
      this.bookClassVisible = false;
      this.bookOpen = false;
      this.bookSingleClassInfo = {
        date: "",
        time: "",
      };
      this.choosedTeacherInfo = {};
      this.duration = 1;
      this.durationIndex = 0;
      this.isFinishChooseTime = false;
      this.isFinishChooseTeacher = false;
      this.choosedIndex = null;
    },
    // 取消选课
    cancleBookClass() {
      this.bookClassVisible = false;
      this.bookOpen = false;
    },
  },
};
</script>

<style lang="scss">
.book-class-container .page-name {
  margin-bottom: 0;
}
</style>
<style lang="scss" scoped>
@import "./index.scss";

.book-class-container {
  // margin-top: 2%;
  // background: chocolate;
  font-weight: 600;
  cursor: default;
  .active {
    color: #224e96;
  }

  .nav-title {
    display: flex;

    .nav-title-item {
      margin-right: 5%;
      cursor: default;
      &:not(.active) {
        cursor: pointer;
      }
      a {
        color: #9e9e9f;
      }
    }
  }
  .content {
    width: 98%;
    height: 50vh;
    background: #ffffff !important;
    border-radius: 10px;
    padding: 3% 0 0 3%;
    margin-right: 2%;
    box-sizing: border-box;
  }
}
</style>
<style lang="scss">
.el-popper {
  // popper 修改样式
  background: #224e96;
  border-radius: 20px;

  .hover {
    background: #224e96;
    // border-radius: 20px;
  }
  .selected {
    color: #f4d737;
  }
  .popper__arrow {
    display: none;
  }
}

//  pick-date-range
.pick-date-range {
  background: #fff;
}
.el-select-dropdown {
  border-radius: 20px;

  .el-scrollbar {
    .el-scrollbar__wrap {
      overflow: auto;
    }
    border-radius: 20px;
    .el-select-dropdown__list {
      padding: 0;
    }

    .el-select-dropdown__wrap {
      background-color: #224e96;
      border-radius: 20px;
      margin-bottom: 0 !important;
      .el-select-dropdown__item {
        color: #ffffff;
        // font-size: 1.2rem;
        // height: 50px;
        // line-height: 50px;

        &.hover {
          color: #224e96;
          background-color: #ffffff;
        }
      }
    }
  }
}
</style>
